const MOBILE_MENU = document.querySelector('.header__menu')
const LANG_ITEM = document.querySelectorAll('.lang-item')
const MAIN_BG = document.querySelector('.main__background')
const DECOR_1 = document.querySelector('.decor-1')
const DECOR_2 = document.querySelector('.decor-2')
const DESC = document.querySelector('.btn-block')
const CATALOG_ITEM = document.querySelectorAll('.catalog-item')

const MODAL_BG = document.querySelector('.modal-bg')
const MODAL_THANKS = document.querySelector('.modal-thanks')
const MODAL_ERROR = document.querySelector('.modal-error')
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')
let width = window.innerWidth

const BG_IMAGE = document.querySelector('.background-img')


// Установка ссылки на язык.
let lang = localStorage.getItem('lang');
let url =  location.href
let urlPath = decodeURI(location.hash)
let mainText = document.querySelector('.text-paragraph')
let descContent = document.querySelectorAll('.text-content')
let mainImg = document.querySelector('.background-img')


descContent.forEach((text) => {
    let desc = text.innerText
    if (urlPath === text.getAttribute('data-desc')) {
        let imgPath = text.getAttribute('data-img')
        mainText.innerText = desc
        mainImg.src = imgPath
    }
})

LANG_ITEM.forEach((item) => {
    let langItem = item.getAttribute('data-lang')

    console.log(langItem)
})


LANG_ITEM.forEach((item) => {
    let element = item.getAttribute("data-lang")
    item.addEventListener('click', () => {
        localStorage.setItem('lang', element)
    })

    if (element !== lang) {
        item.classList.remove('active')
    } else {
        item.classList.add('active')
    }
})

//Остановка прокрутки сайта
function scrollStop() {
    document.querySelector("html").style.overflow = 'hidden'
}

//Восстановление прокрутки сайта
function scrollAuto() {
    document.querySelector("html").style.overflow = ''
}

function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    scrollAuto()
}

function openThanksModal() {
    closeModal()
    scrollStop()
    MODAL_BG.classList.add('active')
    MODAL_THANKS.classList.add('active')

}

function openErrorsModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_ERROR.classList.add('active')
}

closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})


const CERT_LINK = document.querySelectorAll('.cert-link')
const CERT_ITEM = document.querySelectorAll('.cert-item')

CERT_LINK.forEach((item) => {
    let link = item.getAttribute('data-cert')
    item.addEventListener('click', () => {
        CERT_ITEM.forEach((elem) => {

            if (link === elem.getAttribute('data-fancybox')) {
                elem.click()
            }

        })
    })
})


openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                scrollStop()
            }
        })
    })
})

//Подскролл к форме по клику карточки товара
CATALOG_ITEM.forEach((item) => {
    item.addEventListener('click', ()=> {
        if (width <= 767) {
            document.querySelector('.catalog__sidebar').scrollIntoView({ behavior: 'smooth' });
        }
    })
})

//Появление/Исчезновение декора и кнопки при прокрутке в мобиле
let prev = 0

function mainAnimation () {
    document.addEventListener('scroll', ()=> {
        prev = scrollY

        if (scrollY <= 80 && width <= 960) {
            MOBILE_MENU.classList.remove('active')
        }

        if (scrollY >= 70 && width <= 960) {
            MOBILE_MENU.classList.add('active')
        }

        if (scrollY <= 100 && width <= 600) {
            MAIN_BG.style.height = '500px'
        }

        if (scrollY >= 100 && width <= 600) {
            MAIN_BG.style.height = '320px'
        }

        if (scrollY <= 80 && width <= 767) {
            DESC.style.opacity = '1'
        }

        if (scrollY >= 80 && width <= 767) {
            DESC.style.opacity = '0'
            DESC.style.display = 'none'
        }

        if (scrollY <= 80 && width <= 600) {
            DECOR_1.style.opacity = '0'
            DECOR_1.style.transform = "translateY(50px)";
        } else {
            DECOR_1.style.opacity = '1'
            DECOR_1.style.transform = "translateY(0)";
        }

        if (scrollY <= 100 && width <= 600) {
            DECOR_2.style.opacity = '0'
            DECOR_2.style.transform = "translateY(50px)";
        } else {
            DECOR_2.style.opacity = '1'
            DECOR_2.style.transform = "translateY(0)";
        }
    })
}

mainAnimation ()


//Инициализация слайдеров

const cert = new Swiper('.certificate__list', {
    speed: 400,
    spaceBetween: 20,
    slidesPerView: 4,
    slideShow: 4,
    loop: false,
    navigation: {
        nextEl: '.certificate-btn-next',
        prevEl: '.certificate-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1.5,
            centeredSlides: false,
            grid: false,
            spaceBetween: 10,
        },
        425: {
            slidesPerView: 2.5,
        },
        767: {
            slidesPerView: 3,
        },

        1199: {
            slidesPerView: 4.2,
        },
    },

});

const clients = new Swiper('.clients-list', {
    autoplay: true,
    speed: 400,
    spaceBetween: 50,
    loop: true,
    slidesPerView: "auto",
});

document.addEventListener('DOMContentLoaded', () => {
    const width = window.innerWidth
    if (width < 767){
        clients.destroy(true)
    } else {
        clients.init
    }
})

const letters = new Swiper('.letter-list', {
    autoplay: false,
    speed: 400,
    loop: false,
    breakpoints: {
        320: {
            spaceBetween: 10,
            slidesPerView: 2,
            centeredSlides: false,
        },
        767: {
            spaceBetween: 20,
            slidesPerView: 3,
        },
        1199: {
            spaceBetween: 20,
            slidesPerView: 4,
        },
    },
});

//Прикрепить файл
$(document).ready(function () {
    $("#calculation-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-calculation").html(filename);
    });
});

$(document).ready(function () {
    $("#catalog-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#catalog-file-name").html(filename);
    });
});

$(document).ready(function () {
    $("#delivery-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-delivery").html(filename);
    });
});

//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }

});

const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')

function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}


//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault()
        $(this).find('input').each(function() {
            let $input = $(this);

            let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

            if ($input.attr('name') === 'name' && /\d/.test($input.val())) {
                $input.addClass('invalid');
            }
            else if ($input.attr('name') === 'email' && $input.val() && !emailRegex.test($input.val())) {
                $input.addClass('invalid');
            } else {
                $input.removeClass('invalid');
            }
        });
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
        }).done(function () {
            let fileName = 'Прикрепить файл';
            $('form').trigger('reset');
            $("#file-name-calculation").html(fileName);
            $("#catalog-file-name").html(fileName);
            $("#file-name-delivery").html(fileName);
            $("#file-name-modal").html(fileName);
            $('.modal-thanks').fadeIn();
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            openThanksModal()
            sendStats()
        }).fail(function () {
            // openErrorsModal()
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            // $('form').trigger('reset');

        })
    });
});


